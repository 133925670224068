import { Injectable } from '@angular/core';
import { AuthResponseModel } from '../../shared/model/auth-response.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private user: AuthResponseModel | null = null;

  constructor() {}

  public setUser(user: AuthResponseModel | null) {
    this.user = user;
  }

  public getUser(): AuthResponseModel {
    if (this.user === null) {
      throw new Error("Le user n'est pas censé être null");
    }

    return this.user;
  }
}
